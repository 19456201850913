* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.main_note_section, .folder_note_section {
  border: 1px solid black;
  margin-bottom: 15px;
}
.main_note_section p, .folder_note_section p {
  padding-left: 15px;
  padding-right: 15px;
}
.delete_note {
  margin-bottom: 15px;
}
.h1 {
  text-align: center;
}
.Main_route {
  display: flex;
}
.Main_notes {
  width: 80%;
  padding: 30px;
}
.Main_notes section {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.main_addnote_button {
  margin-top: 15px;
  width: 150px;
}
.Main_folders {
  width: 10%;
  display: flex;
  flex-direction: column;
}
.main_folder {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 5px;
}
.active {
  background-color: lightblue;
}
.addnote {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}
.addnote button {
  margin-top: 15px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
